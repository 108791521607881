import * as React from "react";
import { Game, Clown } from "../pages";

export default ({ games, clowns }: { games: Game[]; clowns: Clown[] }) => {
  const clownScores = [...clowns];

  const addClownScore = (name: string, points: number) => {
    clownScores.map((clown) => {
      if (name === clown.name) {
        const pointsBefore = clown.points;
        clown.points = pointsBefore + points;
      }
    });
  };

  games.forEach((game) => {
    const { result } = game;
    if (result.length < 4) return;

    const pointResults = result.splice(0, 3);

    const [winner, second, third] = pointResults;

    addClownScore(winner.name, 3);
    addClownScore(second.name, 2);
    addClownScore(third.name, 1);
  });

  const sortedScores = clownScores.sort((a, b) =>
    a.points > b.points ? -1 : 1
  );

  return (
    <section>
      <ul>
        {sortedScores.map((clown) => {
          const { name, points } = clown;
          return (
            <li key={`score-${name}`}>
              {points} poäng - {name}
            </li>
          );
        })}
      </ul>
    </section>
  );
};
